<template>
  <section>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      style="width: 50%"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title" size="small"></el-input>
      </el-form-item>
      <el-form-item label="描述" prop="remark">
        <el-input
          type="textarea"
          v-model="ruleForm.remark"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <el-upload
          class="upload-demo"
          :action="uploadBase"
          :file-list="fileList"
          :headers="headers"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
        >
          <el-button size="small" type="text">上传附件</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <quill-editor
      ref="myTextEditor"
      v-model="content"
      :options="editorOption"
      style="height: 400px"
    ></quill-editor>
    <el-upload
      :show-upload-list="false"
      :on-success="handleQuill"
      :max-size="2048"
      multiple
      :action="uploadBase"
      :headers="headers"
      style="display: none"
      :file-list="fileList"
    >
      <el-button id="upload"></el-button>
    </el-upload>
    <el-button type="primary" @click="submitForm('ruleForm')" size="small">{{
      $route.params.id ? "修改" : "添加"
    }}</el-button>
  </section>
</template>

<script>
import { Save, Detail, Update } from "@/api/infomation/knowledge";
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link", "image"],
  ["clean"], // remove formatting button
];
export default {
  data() {
    return {
      content: "",
      editorOption: {
        placeholder: "编辑文章内容",
        modules: {
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              image: function (value) {
                if (value) {
                  // 调用iview图片上传
                  document.querySelector("#upload").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
      },
      dialogVisible: false,
      ruleForm: {
        title: "",
        remark: "",
      },
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
      },
      fileList: [],
      uploadBase: process.env.VUE_APP_BASE_URL + "/attach/save",
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      flag: false,
    };
  },

  mounted() {
    let id = this.$route.params.id;
    if (id) {
      Detail(id).then((res) => {
        this.content = res.data.data.content;
        this.ruleForm.title = res.data.data.title;
        this.ruleForm.remark = res.data.data.remark;
        this.fileList = JSON.parse(res.data.data.attach);
      });
    }
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ...this.ruleForm,
            ...{ content: this.content, attach: JSON.stringify(this.fileList) },
          };
          if (this.$route.params.id) {
            Update(this.$route.params.id, params).then((res) => {
              this.$message.success("操作成功");
              this.flag = true;
              this.$router.go(-1);
            });
          } else {
            Save(params).then((res) => {
              this.$message.success("操作成功");
              this.flag = true;
              this.$router.go(-1);
            });
          }
        } else {
          return false;
        }
      });
    },
    handleSuccess(res, file, list) {
      if(res.code==1){
        this.fileList = list;
      }else{
        this.fileList.pop()
        this.$message.error(res.msg)
      }
      
    },
    handleRemove(res, file, list) {
      this.fileList = list;
    },
    handleQuill(res) {
      let quill = this.$refs.myTextEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(
          length,
          "image",
          process.env.VUE_APP_BASE_URL + "/" + res.data.url
        );
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error("图片插入失败");
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (from.path == "/epidemicKnowledgeEdit" && !this.flag) {
      setTimeout(() => {
        this.$confirm(
          "您有未提交的修改，将在离开页面后丢失，确认是否离开?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            next();
          })
          .catch(() => {});
      }, 200);
    } else {
      next();
    }
  },
};
</script>

<style>
.ql-container {
  height: 85%;
}
</style>